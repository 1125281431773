import React, { Component } from 'react';
import random from 'canvas-sketch-util/random';
import { lerp } from 'canvas-sketch-util/math';
import './App.css';

const WHITESPACE = 32;

const BLACK = `#212121`;
const PALETTE = {
  BLUE_LIGHT: '#A7DBD8',
  BLUE: '#69D2E7',
  ORANGE: '#FA6900',
  ORANGE_LIGHT: '#F38630',
  SAND: '#E0E4CC',
  WHITE: '#fff',
  DARK_GREY: '#393939',
  BLACK,
};

const GRID_SIZE_Y = 400;
const GRID_SIZE_X = 16 / 9 * GRID_SIZE_Y;

const GRID_POINTS = [];

(() => {
  for (let j = 0; j < GRID_SIZE_Y; j++) {
    for (let i = 0; i < GRID_SIZE_X; i++) {
      GRID_POINTS.push({
        u: i / (GRID_SIZE_X - 1),
        v: j / (GRID_SIZE_Y - 1)
      });
    }
  }
})()

const PROGRESS_MARGIN = [ 16, 56 ];
const PROGRESS_HEIGHT = 64;
const PROGRESS_LINE_WIDTH = 2;
const PROGRESS_STROKE_STYLE = '#e7e7e7';
const PROGRESS_COMPLETE_FILL = PALETTE.BLUE_LIGHT;
const PROGRESS_INCOMPLETE_FILL = PALETTE.DARK_GREY;
const PROGRESS_LEVEL_MARK_STYLE = PALETTE.WHITE;
const PROGRESS_LEVEL_MARK_WIDTH = 2;


const LEVEL_STYLE = BLACK;
const LEVEL_FONT = `32px Futura`;
const LEVEL_2_FONT = `24px Futura`;
const LEVEL_MARGIN = [ PROGRESS_MARGIN[0] + PROGRESS_HEIGHT + 32, 0 ];
const LEVELS = [
  { playhead: 0, name: 'Hola', kps: 0, },
  { playhead: 0.02, name: '¿Que tal?', kps: 2, },
  { playhead: 0.04, name: 'Bien!', kps: 0, },
  { playhead: 0.09, name: `Let's push some buttons?`, kps: 4, },
  { playhead: 0.1, name: 'Perfect!', kps: 0, },
  { playhead: 0.11, name: 'So, hey, Cristina', kps: 0, },
  { playhead: 0.13, name: 'Happy 2019!', kps: 2, },
  { playhead: 0.14, name: 'Happy 2019!', kps: 0, },
  { playhead: 0.185, name: 'YEAAAH!!', kps: 12, },
  { playhead: 0.19, name: 'YEAAAAH!!', kps: 12, },
  { playhead: 0.20, name: 'YEAAAAAH!!', kps: 12, },
  { playhead: 0.21, name: 'YEAAAAAH!!!', kps: 12, },
  { playhead: 0.22, name: `It's gonna be a good year`, kps: 1, },
  { playhead: 0.265, name: `It's gonna be a great year!`, kps: 14, },
  { playhead: 0.29, name: ``, kps: 0, },
  { playhead: 0.30, name: `Some challenges lie ahead`, kps: 0, },
  { playhead: 0.35, name: `BUT`, kps: 5, },
  { playhead: 0.37, name: `Those are worthy challenges`, kps: 10, },
  { playhead: 0.40, name: `Those are worthy challenges`, kps: 5, },
  { playhead: 0.42, name: `Those are worthy challenges`, kps: 2, },
  { playhead: 0.45, name: `AND there will be lots of fun as well!`, kps: 0, },
  { playhead: 0.47, name: `Lots of good fun!`, kps: 0, },
  { playhead: 0.54, name: `Hehe`, kps: 0, },
  { playhead: 0.57, name: `You know what to do`, kps: 0, },
  { playhead: 0.60, name: `Keep smashing it!!!`, kps: 20, },
  { playhead: 0.635, name: `Find the good`, kps: 10, },
  { playhead: 0.664, name: `Explore yourself`, kps: 22, },
  { playhead: 0.68, name: `Explore yourself`, kps: 24, },
  { playhead: 0.69, name: `And the world`, kps: 25, },
  { playhead: 0.70, name: `And the world`, kps: 4, },
  { playhead: 0.735, name: `We are here for you`, kps: 20, },
  { playhead: 0.74, name: `We are here for you`, kps: 23, },
  { playhead: 0.76, name: `We are here for you`, kps: 25, },
  { playhead: 0.80, name: `Happy New Year!`, kps: 10, },
  { playhead: 0.82, name: `Happy New Year!`, kps: 12, },
  { playhead: 0.84, name: `Happy New Year!`, kps: 1, },
  { playhead: 0.93, name: `Happy New Year!`, kps: 10 },
  { playhead: 0.94, name: `Happy New Year!`, kps: 11 },
  { playhead: 0.95, name: `Happy New Year!`, kps: 14 },
  { playhead: 0.96, name: `Happy New Year!`, kps: 18 },
  { playhead: 0.97, name: `Happy New Year!`, kps: 24 },
  { playhead: 0.98, name: `Happy New Year!`, kps: 0 },
];

// const KPS_REFRESH_INTERVAL = 1000;
const KPS_MARGIN = [ LEVEL_MARGIN[0] + 80 , 0 ];
const KPS_STYLE = BLACK;
const KPS_FONT = `16px Futura`;

const KPS_GREEN = PALETTE.BLUE_LIGHT;
const KPS_DEEP_GREEN = PALETTE.BLUE;
const KPS_RED = PALETTE.ORANGE_LIGHT;
const KPS_DEEP_RED = PALETTE.ORANGE;


class App extends Component {
  constructor(props) {
    super(props);

    this._startEventTime = null;
    this._start = null;
    this._playing = false;
    this._pressed = [];

    this.state = {
      viewportWidth: window.viewportWidth,
      viewportHeight: window.viewportHeight,
    };

    this._setViewportSize = () => {
      let viewportHeight = window.innerHeight;
      if (viewportHeight < 800) {
        viewportHeight = 800;
      }

      this.setState({
        viewportHeight,
        viewportWidth: window.innerWidth,
      });
    };

    this._play = async () => {
      console.log('play');
      await this._audio.play();
      // this._audio.currentTime = 110;
      const now = Date.now();
      this._start = now;
      this._playing = true;
      this._loop();
    };

    this._loop = () => {
      const { viewportWidth, viewportHeight } = this.state;

      this._fgContext.clearRect(0, 0, viewportWidth, viewportHeight);
      const progress = this._audio.currentTime / this._audio.duration;
      const level = LEVELS.reduce((a, l) => {
        if (progress > l.playhead) {
          return l;
        }

        return a;
      });

      const kps = this._calcKps();

      this._checkKps(kps, level);
      this._drawProgress(progress);
      this._drawLevel(level);
      this._drawKps({ kps, level, progress });
      this._drawKeys({ kps, level, progress });
      requestAnimationFrame(this._loop);
    };

    this._calcKps = () => {
      const now = Date.now();
      const frameStart = now - 1000;
      const pressed = this._pressed;

      let pressCount = 0;

      for (let i = pressed.length - 1; i >= 0; i--) {
        let press = pressed[i];
        if (press.time >= frameStart) {
          pressCount += 1;
        }

        if (press.time < frameStart) {
          break;
        }
      }

      return pressCount;
    };

    this._checkKps = (kps, level) => {
      if (kps < level.kps / 2) {
        this._audio.playbackRate = 0.2;
        return;
      }

      if (kps < level.kps) {
        this._audio.playbackRate = 0.5;
      }

      if (kps >= level.kps) {
        this._audio.playbackRate = 1;
      }
    };


    this._drawProgress = (progress) => {
      const { viewportWidth, } = this.state;
      const context = this._fgContext;

      const [vMargin, hMargin] = PROGRESS_MARGIN;
      const x = hMargin;
      const y = vMargin;
      const width = viewportWidth - 2 * hMargin;
      const height = PROGRESS_HEIGHT;


      // fill
      context.fillStyle = PROGRESS_INCOMPLETE_FILL;
      context.fillRect(x, y, width, height);

      context.font = `16px Futura`;
      context.fillStyle = '#050505';
      context.fillText(`${Math.round(progress * 10000) / 100}%`, x, y);

      context.fillStyle = PROGRESS_COMPLETE_FILL;
      context.fillRect(x, y, width * progress, height);

      // border
      context.beginPath();
      context.rect(
        hMargin,
        vMargin,
        viewportWidth - 2 * hMargin,
        PROGRESS_HEIGHT
      );
      context.closePath();

      context.lineWidth = PROGRESS_LINE_WIDTH;
      context.strokeStyle = PROGRESS_STROKE_STYLE;
      context.stroke();

      context.fillStyle = PROGRESS_LEVEL_MARK_STYLE;
      for (let level of LEVELS) {
        const pos = level.playhead * width;
        const w = PROGRESS_LEVEL_MARK_WIDTH;
        context.fillRect(x + pos - w / 2, y, w, height);
      }
    };

    this._drawKps = ({ kps, level, progress }) => {
      const context = this._fgContext;
      const { viewportWidth, } = this.state;
      const text = `Current: ${kps}`;
      context.font = KPS_FONT;
      const metrics = context.measureText(text);
      context.strokeStyle = null;
      context.fillStyle = KPS_STYLE;
      context.fillText(
        text,
        (viewportWidth - metrics.width) / 2,
        KPS_MARGIN[0]
      );

      const width = 60;
      const height = 320;
      const x = (viewportWidth - width) / 2;
      const y = KPS_MARGIN[0] + 16;
      context.beginPath();
      context.rect(
        x,
        y,
        width,
        height
      );

      context.lineWidth = 2;
      context.strokeStyle = KPS_STYLE;
      context.stroke();

      // current kps
      // const now = Date.now();
      // const { pressed } = this.state;
      // let lastPress = pressed[pressed.length - 1];
      // let lastPressTime = (lastPress || {}).time || 0;
      // let nextPress;

      // if (level.kps) {
      //   nextPress = lastPressTime + 2000 / level.kps;
      // }

      // if (!level.kps) {
      //   const nextLevel = LEVELS.reduce((a, l) => {
      //     if (a.getNext) {
      //       return {
      //         getNext: false,
      //         level: l
      //       };
      //     }

      //     if (l.name === level.name) {
      //       return {
      //         level: null,
      //         getNext: true,
      //       };
      //     }

      //     return a;
      //   }, { getNext: false, level: null }).level;

      //   if (!nextLevel) {
      //     nextPress = now;
      //   }

      //   if (nextLevel) {
      //     const add =  nextLevel.playhead * this._audio.duration * 1000;
      //     nextPress = start + 2 * add;
      //   }
      // }

      const hitMark = 0.5;
      // const percent =
      //   Math.min(
      //     0.98,
      //     (now - lastPressTime) / (nextPress - lastPressTime)
      //   );

      // const percentKps = (
      //   level.kps === 0 ?
      //     1 :
      //     Math.max(0, 1 - kps / level.kps)
      // );

      // let percentHeight;
      // percentHeight = (
      //   expoInOut(percent) *
      //   0.3 * height
      // );

      // const percent = (now - lastPressTime) / (nextPress - lastPressTime);

      let ratio;
      if (level.kps === 0 && this._playing) {
        ratio = 1;
      }

      if (level.kps === 0 && !this._playing) {
        ratio = 0;
      }

      if (level.kps !== 0) {
        ratio = Math.max(Math.min(kps / (2 * level.kps), 1), 0.01);
      }

      const maxHeight = height - height * ratio;
      const percentHeight = maxHeight;

      let color;
      if (ratio < 0.3) {
        color = KPS_DEEP_RED;
      }

      if (ratio < 0.5) {
        color = KPS_RED;
      }

      if (ratio >= 0.5) {
        color = KPS_GREEN;
      }

      if (ratio > 0.9) {
        color = KPS_DEEP_GREEN;
      }
      context.fillStyle = color;
      context.fillRect(
        x,
        y + percentHeight,
        width,
        height - percentHeight,
      );

      // "enough" mark
      context.beginPath();
      context.moveTo(x, y + height * hitMark);
      context.lineTo(x + width, y + height * hitMark);
      context.stroke();

      context.fillStyle = color;
      const pressText = 'PRESS ANY KEY';
      const pressTextMetrics = context.measureText(pressText);
      context.fillText(
        pressText,
        (viewportWidth - pressTextMetrics.width) / 2,
        KPS_MARGIN[0] + height + 60
      );
    };

    this._drawKeys = ({ level, progress, kps }) => {
      const bgContext = this._bgContext;
      const { viewportHeight, viewportWidth } = this.state;
      bgContext.fillStyle = '#eee';
      bgContext.fillRect(0, 0, viewportWidth, viewportHeight);

      const now = Date.now();
      const pressed = this._pressed;
      const showDuration = 12000;

      for (let i = pressed.length - 1; i >= 0; i--) {
        const press = pressed[i];

        if (level.kps && press.time < now - showDuration * level.kps) {
          continue;
        }

        const timePassed = (now - press.time);
        let opacity;
        if (level.kps === 0) {
          opacity = 1;
        } else {
          const showTime = showDuration * level.kps;
          opacity = 1 - timePassed / showTime;
        }

        const point = {
          u: press.startU,
          v: press.startV,
        };

        point.u = (
          point.u +
          Math.floor(timePassed * press.speed[0] / 1000) *
          (1 / GRID_SIZE_X)
        );
        point.v = (
          point.v +
          Math.floor(timePassed * press.speed[1] / 1000) *
          (1 / GRID_SIZE_Y)
        );

        const noiseValue = random.noise4D(
          point.u,
          point.v,
          i,
          progress,
        );

        bgContext.save();
        bgContext.font = `${40 + Math.round(noiseValue * 100)}px Arial`;
        bgContext.fillStyle = `rgba(243, 134, 48, ${opacity})`;
        bgContext.translate(
          point.u * viewportWidth,
          point.v * viewportHeight
        );
        bgContext.rotate(noiseValue * Math.PI * 2)
        bgContext.fillText(press.key, 0, 0);
        bgContext.restore();
      }
    };

    this._drawLevel = (level) => {
      const context = this._fgContext;
      const { viewportWidth } = this.state;

      context.font = LEVEL_FONT;
      let text = `${level.name}`;
      let metrics = context.measureText(text);
      context.fillStyle = LEVEL_STYLE;
      context.strokeStyle = null;

      context.fillText(
        text,
        (viewportWidth - metrics.width) / 2,
        LEVEL_MARGIN[0]
      );

      context.font = LEVEL_2_FONT;
      let text2 = `target: ${level.kps}`;
      let metrics2 = context.measureText(text2);
      context.fillStyle = 'grey';
      context.strokeStyle = null;

      context.fillText(
        text2,
        (viewportWidth - metrics2.width) / 2,
        LEVEL_MARGIN[0] + 36
      );
    };
  }

  componentDidMount() {
    let fgContext = this._fg.getContext('2d');
    let bgContext = this._bg.getContext('2d');

    this._fgContext = fgContext;
    this._bgContext = bgContext;

    this._setViewportSize();

    window.addEventListener('resize', () => {
      this._setViewportSize();
    });

    window.addEventListener('keydown', e => {
      if (e.keyCode === WHITESPACE) {
        e.preventDefault();
      }
    });

    window.addEventListener('keyup', (e) => {
      let { key } = e;

      if (!key.trim().length) {
        key = e.code;
      }

      const point = random.pick(GRID_POINTS);
      this._pressed.push({
        key,
        keyCode: e.keyCode,
        startU: point.u,
        startV: point.v,
        speed: [
          random.rangeFloor(-100, 100),
          random.rangeFloor(-100, 100),
        ],
        time: this._start + (e.timeStamp - this._startEventTime)
      });

      if (!this._playing) {
        this._startEventTime = e.timeStamp;
        this._play();
      }
    });

    this._drawWelcomeScreen = () => {
      if (this._playing) {
        return;
      }

      const { viewportWidth, viewportHeight } = this.state;
      const now = Date.now();
      // const duration = 3000;
      // const diff = now - this._welcomeStartTime;
      // const playhead = Math.sin(2 * Math.PI *
      //   (now - Math.floor(diff / duration) * duration) / duration
      // ) / 50;

      const playhead = now;

      if (!this._lettersMap) {
        const letters = 'Happy 2019!';
        const lettersMap = [];
        let pos = random.rangeFloor(500, 700);
        let i = 0;
        while (pos < GRID_POINTS.length) {
          const letter = letters[i % letters.length];
          lettersMap.push({
            letter,
            rotation: random.noise1D(letter.charCodeAt(0)),
            ...GRID_POINTS[pos],
          });
          i += 1;
          pos += random.rangeFloor(1000, 3000);
        }

        this._lettersMap = lettersMap;
      }

      const lettersMap = this._lettersMap;

      const bgContext = this._bgContext;
      const fgContext = this._fgContext;
      bgContext.fillStyle = '#eee';
      bgContext.fillRect(0, 0, viewportWidth, viewportHeight);

      for (let l of lettersMap) {
        const noiseValue = random.noise3D(
          l.u,
          l.v,
          playhead / 4000
        );
        bgContext.save();

        bgContext.font = `80px Futura`;
        bgContext.fillStyle =
          `rgba(243, 134, 48, ${0.1 + Math.abs(noiseValue)})`;
        bgContext.translate(
          lerp(120, viewportWidth - 240, l.u),
          lerp(120, viewportHeight - 240, l.v)
        );
        bgContext.rotate(l.rotation * Math.PI / 5)
        bgContext.fillText(l.letter, 0, 0);

        bgContext.restore();
      }


      fgContext.font = '32px Futura';
      fgContext.fillStyle = PALETTE.DARK_GREY;
      const pressText = 'PRESS ANY KEY';
      const pressTextMetrics = fgContext.measureText(pressText);
      fgContext.fillText(
        pressText,
        (viewportWidth - pressTextMetrics.width) / 2,
        KPS_MARGIN[0] + 100
      );

      requestAnimationFrame(this._drawWelcomeScreen);
    };
  }

  componentDidUpdate() {
    if (this._playing) {
      this._drawProgress(0);
      this._drawKps({ kps: 0, level: LEVELS[0], progress: 0 });

      this._bgContext.fillStyle = '#eee';
      this._bgContext.fillRect(
        0, 0,
        this.state.viewportWidth,
        this.state.viewportHeight
      );

      return;
    }

    this._welcomeStartTime = Date.now();
    this._drawWelcomeScreen();
  }


  render() {
    return (
      <div className='app'>
        <canvas
          width={this.state.viewportWidth}
          height={this.state.viewportHeight}
          ref={bg => this._bg = bg}
          id='bg'></canvas>
        <canvas
          width={this.state.viewportWidth}
          height={this.state.viewportHeight}
          ref={fg => this._fg = fg}
          id='fg'></canvas>
        <audio
          ref={a => this._audio = a}
          id='a'
          src='audio/lalluvia.mp3'></audio>
      </div>
    );
  }
}

export default App;
